import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, useMediaQuery } from "@mui/material";

// TODO look at swiper

function Item(props) {
  const isMobile = useMediaQuery("(max-width:600px)"); // Detects mobile devices

  return (
    <Paper
      elevation={0}
      style={{
        margin: "0",
        backgroundColor: "transparent",
        color: "white",
        position: "relative",
      }}
    >
      <img
        src={props.item.imageUrl}
        alt={props.item.name}
        style={{ width: "100%", height: "25vh", objectFit: "cover" }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          backgroundColor: props.item.hasBackground
            ? "rgba(0, 0, 0, 0.5)"
            : "none",
          padding: "10px",
          borderRadius: "10px",
          maxWidth: "90%",
        }}
      >
        <h2 style={{ margin: 0 }}>{props.item.name}</h2>
        <p>
          {isMobile ? props.item.mobileDescription : props.item.description}
        </p>
        <p style={{ fontWeight: "bold" }}>{props.item.specialMessage}</p>
      </div>
    </Paper>
  );
}

function ImageCarousel() {
  const items = [
    {
      name: "Empathy & Understanding",
      description:
        "Dr. Rothenberg's practice is rooted in empathy, providing a secure space for you to explore and heal. Specializing in sensitive dialogues, she ensures comfort and confidentiality.",
      mobileDescription: "",
      imageUrl:
        "https://drannerothenberg.com/wp-content/uploads/revslider/home-1/slide1.jpg",
      hasBackground: false,
    },
    {
      name: "Non-Judgemental & Confidential ",
      description:
        "As a board-certified clinical sexologist, Dr. Rothenberg is dedicated to addressing sexual dysfunction with expertise and sensitivity. Embrace a fulfilling and healthy sexual life.",
      mobileDescription: "",
      imageUrl:
        "https://drannerothenberg.com/wp-content/uploads/revslider/home-1/slide2.jpg",
      hasBackground: false,
    },
    {
      name: "Inclusive & Supportive",
      description:
        "Dr. Rothenberg provides specialized support for LGBTQ+ individuals and couples, fostering a respectful and inclusive environment for all to discuss and resolve their unique challenges.",
      mobileDescription: "",
      imageUrl:
        "https://images.unsplash.com/photo-1548475390-f6908921aaf8?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      hasBackground: false,
    },
  ];

  return (
    <Carousel
      animation="slide"
      indicators={false}
      navButtonsAlwaysInvisible={true}
      interval={15000} // Each slide will be visible for 8 seconds
      duration={1000} // Slowing down the transition to 2 seconds
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
