import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Modal,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import HealingIcon from "@mui/icons-material/Healing";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ComputerIcon from "@mui/icons-material/Computer";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import PsychologyIcon from "@mui/icons-material/Psychology";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  PrideFlagIcon,
  TransgenderFlagIcon,
  AutismInfinityIcon,
  ChildrenAutismInfinityIcon,
} from "./RainbowIcon"; // Import your custom RainbowIcon
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

function ServiceModal({ service }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title={service.description} placement="top">
        <Chip
          icon={<service.Icon />}
          label={service.label}
          onClick={handleOpen}
          variant="outlined"
          sx={{ width: "auto", justifyContent: "center", my: 1, mx: 1 }}
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {service.label}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {service.description}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

function ServicesList() {
  const categories = {
    Individual: [
      {
        label: "Autism Support for Adults",
        description:
          "Specialized support addressing the unique life and social challenges faced by autistic adults, including workplace success, social relationships, and personal development.",
        Icon: AllInclusiveIcon,
      },
      {
        label: "Autism Support for Children",
        description:
          "Helps children with autism develop essential social skills, manage sensory sensitivities, and improve communication abilities, all within a nurturing environment.",
        Icon: AutismInfinityIcon,
      },
      {
        label: "Female / Male Survivors",
        description:
          "Offers compassionate support and therapeutic strategies for survivors of sexual abuse, focusing on healing trauma and reclaiming personal strength.",
        Icon: HealingIcon,
      },
      {
        label: "LGBTQ Counseling",
        description:
          "Provides a safe space for exploring gender and sexual identity issues, offering support for related emotional and social challenges.",
        Icon: PrideFlagIcon,
      },
      {
        label: "Online Sexual Addiction",
        description:
          "Addresses compulsive behaviors and challenges associated with online sexual activities, helping individuals regain control and improve their relational health.",
        Icon: ComputerIcon,
      },
      {
        label: "Transgender Counseling",
        description:
          "Provides support for individuals exploring or undergoing gender transition, including emotional support, navigating social changes, and accessing medical resources. Focuses on building a positive sense of self and fostering resilience through the transition process.",
        Icon: TransgenderFlagIcon,
      },
      {
        label: "Youth Counseling",
        description:
          "Tailored counseling for adolescents dealing with issues related to gender identity, sexual orientation, and interpersonal relationships, fostering a supportive environment for growth and self-discovery.",
        Icon: ChildCareIcon,
      },
    ],
    Relationship: [
      {
        label: "Marriage / Couples Counseling",
        description:
          "Facilitates stronger relationships through improved communication, conflict resolution, and enhanced intimacy and understanding between partners.",
        Icon: PeopleIcon,
      },
      {
        label: "Sex Therapy for Couples",
        description:
          "Specifically addresses sexual issues within relationships, promoting healthier sexual communication, understanding, and satisfaction.",
        Icon: FavoriteIcon,
      },
      {
        label: "Straight Spouse Support",
        description:
          "Offers guidance and support for spouses of LGBTQ individuals, focusing on navigating changes in relationships and understanding gender and sexual identity shifts.",
        Icon: PsychologyIcon,
      },
    ],
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      <Grid container spacing={2}>
        {Object.entries(categories).map(([category, services], idx) => (
          <Grid item xs={12} sm={6} md={idx === 0 ? 8 : 4} key={idx}>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
            >
              {category} Services
            </Typography>
            {services.map((service, index) => (
              <ServiceModal key={index} service={service} />
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServicesList;
