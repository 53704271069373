import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoneyOutlined";
import ContactMailIcon from "@mui/icons-material/ContactMailOutlined";
import ContactInfo from "./ContactInfo";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { styled } from "@mui/material/styles";

const ResponsiveLogo = styled("img")(({ theme }) => ({
  width: "auto", // Maintain aspect ratio
  marginRight: "8px", // Consistent spacing
  height: "50px", // Default height
  [theme.breakpoints.down("sm")]: {
    height: "40px", // Smaller height on small devices
  },
  [theme.breakpoints.down("xs")]: {
    height: "30px", // Even smaller height on extra small devices
  },
}));

function Nav() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" color="default" elevation={4}>
      {/* Sub-navigation for contact information */}
      <ContactInfo />

      {/* Main navigation and basic information */}
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fcf7f1",
          height: { xs: "75px", md: "150px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: { xs: "5%", md: "10%" },
          }}
        >
          <ResponsiveLogo
            src="http://www.drannerothenberg.com/wp-content/uploads/2016/07/logo_light.png"
            alt="Dr. Anne Rothenberg Logo"
          />
          {/* <Typography
            variant="h6"
            sx={{
              color: "black",
              display: "block", // Only display this text on non-mobile screens
            }}
          >
            Dr. Anne Rothenberg
          </Typography> */}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            marginRight: "10%",
          }}
        >
          <Button color="inherit" href="#" sx={{ color: "black" }}>
            Home
          </Button>
          <Button color="inherit" href="#" sx={{ color: "black" }}>
            Services
          </Button>
          <Button color="inherit" href="#" sx={{ color: "black" }}>
            Rates
          </Button>
          <Button color="inherit" href="#" sx={{ color: "black" }}>
            Contact
          </Button>
        </Box>

        {/* Mobile menu */}
        <IconButton
          color="inherit"
          aria-label="menu"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{
            ml: 2,
            display: {
              xs: "block",
              md: "none",
              color: "black",
              marginRight: "5%",
            },
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#142e29" }} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <Diversity3Icon style={{ color: "#142e29" }} />
                </ListItemIcon>
                <ListItemText primary="Services" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <AttachMoneyIcon style={{ color: "#142e29" }} />
                </ListItemIcon>
                <ListItemText primary="Rates" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <ContactMailIcon style={{ color: "#142e29" }} />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Nav;
