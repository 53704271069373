import React from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import ServicesChips from "./ServiceChips";

function AboutSection() {
  const theme = useTheme();
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1400px)");

  return (
    <Box sx={{ maxWidth: "75%", mx: "auto", my: 4, px: 2 }}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="https://i.ibb.co/DMgvfr9/dranneheadshot-300x450-1-transformed.jpg"
            alt="Dr. Anne Rothenberg"
            style={{
              maxWidth: { xs: "auto", sm: "70%" },
              height: "300px",
              marginBottom: 20,
              borderRadius: "15px", // Adds rounded corners
              border: "5px solid #F1F6FC", // Adds a thick border with a specified color
              padding: "10px", // Adds space between the image and the border
              backgroundColor: "#F1F6FC", // Sets the background color behind the image
            }}
          />

          <Typography
            variant="h5"
            sx={{ mt: 2, mb: 1, textAlign: "center", fontWeight: "medium" }}
          >
            Dr. Anne Rothenberg
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ mb: 1, textAlign: "center", fontWeight: "regular" }}
          >
            Ph.D., LMHC 14391
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "text.secondary", mb: 1 }}
          >
            Licensed Mental Health Counselor
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "text.secondary", mb: 1 }}
          >
            Board Certified Clinical Sexologist
          </Typography>
        </Grid>

        {/* Left column */}
        <Grid item xs={12} sm={7}>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              textAlign: { xs: "justify", sm: "left" },
              mt: { sm: 4 },
            }}
          >
            Dr. Anne Rothenberg is renowned for her warm, accepting, and
            non-judgmental approach, which fosters a safe and welcoming
            environment for discussing sensitive issues. Her open and direct
            communication style ensures that individuals, couples, and families
            feel comfortable addressing challenges that are often difficult to
            talk about.
          </Typography>

          <Typography
            variant="body1"
            sx={{ mb: 2, textAlign: { xs: "justify", sm: "left" } }}
          >
            As a Board Certified Clinical Sexologist and a globally recognized
            expert in human sexuality and sexual behavior, Dr. Rothenberg offers
            specialized counseling in Transsexual and Transgender issues,
            adhering to the highest standards of care set by the World
            Professional Association for Transgender Health (WPATH).
          </Typography>

          <Typography
            variant="body1"
            sx={{ mb: 2, textAlign: { xs: "justify", sm: "left" } }}
          >
            At the Center for Counseling and Sexual Health, Dr. Rothenberg is
            dedicated to helping her clients enhance their self-esteem, improve
            their relationships, and enjoy a fulfilling sexual life. Her
            practice is committed to helping individuals and couples achieve a
            better quality of life through personalized and effective
            therapeutic strategies.
          </Typography>
          {!isIpad && (
            <>
              <ServicesChips />
            </>
          )}
        </Grid>

        {/* Right column */}
      </Grid>

      {isIpad && (
        <>
          <ServicesChips />
        </>
      )}
    </Box>
  );
}

export default AboutSection;
