import React from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function ContactSection() {
  return (
    <Box
      sx={{
        backgroundColor: "#fcf7f1", // light background color
        color: "black",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 1, marginTop: "15px", textAlign: "center" }}
      >
        Have Questions?
      </Typography>
      <Typography sx={{ mb: 1 }}>Contact me directly.</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
          mb: 4,
          mt: 4,
        }}
      >
        <Link
          href="tel:407-797-5469"
          color="inherit"
          sx={{
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              bgcolor: "#708840",
              color: "white",
              ":hover": { bgcolor: "#5a6f32" },
              mb: 1,
            }}
          >
            <PhoneIcon />
          </IconButton>
          <Typography variant="subtitle1">407-797-5469</Typography>
        </Link>
        <Link
          href="mailto:RothenbergCounseling@gmail.com"
          color="inherit"
          sx={{
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: { xs: 4, md: 0 },
          }}
        >
          <IconButton
            sx={{
              bgcolor: "#708840",
              color: "white",
              ":hover": { bgcolor: "#5a6f32" },
              mb: 1,
            }}
          >
            <EmailIcon />
          </IconButton>
          <Typography variant="subtitle1">
            RothenbergCounseling@gmail.com
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default ContactSection;
