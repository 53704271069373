import React from "react";
import AboutSection from "./About";
import ContactSection from "./Contact";
import GoogleMapsSection from "./Map";
import ImageCarousel from "./Carousel";

function Home() {
  return (
    <>
      <ImageCarousel />
      <AboutSection />
      <ContactSection />
      <GoogleMapsSection />
    </>
  );
}

export default Home;
