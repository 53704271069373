import React from "react";

function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        backgroundColor: "#333",
        color: "white",
        textAlign: "center",
        borderTop: "1px solid #444",
        fontSize: "0.85rem",
        padding: "10px 0", // Adds padding inside the footer
        boxSizing: "border-box", // Ensures padding and border are included in the element's dimensions
      }}
    >
      <p>
        Anne Rothenberg, Ph.D., LMHC 14391, is a State of Florida Licensed
        Mental Health Counselor, Board Certified Clinical Sexologist, Diplomate
        of the American Board of Sexology and an internationally recognized
        specialist in Human Sexuality and Human Sexual Behavior.
      </p>
    </footer>
  );
}

export default Footer;
