import React from 'react';

function GoogleMapsSection() {
const googleMapsSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1401.236555477276!2d-81.39789018402558!3d28.636073381106465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e770670026a785%3A0xbe00777d37a9ce98!2sDr.+Anne+Rothenberg!5e0!3m2!1sen!2sus!4v1492624044001`;

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '400px', // Adjust the height as needed
    }}>
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        style={{ border: 0 }}
        src={googleMapsSrc}
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default GoogleMapsSection;
